/* CreatorInfo.css */
.main-creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 60px 15px 25px 15px;
  z-index: 20;
}

.main-creator-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.main-creator-name h4 {
  margin: 0px;
  color: #FFFFFF;
}