.AbcFooter {
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 7px 23px;
  background: #252836;
  box-sizing: border-box;
  opacity: 1;
}

.AbcFooterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.AbcFooterTitle img {
  height: 18px;
  width: auto;
  display: block;
  margin: 0 auto;
}

.AbcFooterDescription {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.8rem;
  text-align: center;
  line-height: 1.35;
}

