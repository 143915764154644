/* CreatorHeader.css */
.creator-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    text-align: end;
    z-index: 10;
    background-image: url('/public/header-gradient.png');
    background-size: cover;
    background-position: center;
    height: 115px;
}
.brand-note {
    display: inline-block;
    font-size: 14px;
    padding: 10px 10px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 300;
}

/* .header-bg {
    background-color: #FFFFFF;
    height: 115px;
    opacity: 0.8;
    background-image:  linear-gradient(#EEEEEE 1.8px, transparent 1.8px), linear-gradient(90deg, #EEEEEE 1.8px, transparent 1.8px), linear-gradient(#EEEEEE 0.9px, transparent 0.9px), linear-gradient(90deg, #EEEEEE 0.9px, #FFFFFF 0.9px);
    background-size: 45px 45px, 45px 45px, 9px 9px, 9px 9px;
    background-position: -1.8px -1.8px, -1.8px -1.8px, -0.9px -0.9px, -0.9px -0.9px;
} */