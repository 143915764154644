/* AdPage.css */
.AdPage {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    box-sizing: border-box;
    background-color: #1F1D2B;
}
  
.LandingPageButton {
    position: sticky;
    bottom: 23px;
    z-index: 10;
    display: flex;
    padding: 7px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 0 3px 7px 0 rgba(44, 160, 226, 0.5);
    background: #2CA0E2;
    border: none;
    transition: background-color 0.3s;
    gap: 7px;
    color: white;
    width: 100%;
    max-width: 330px;
    font-weight: 300;
}
  
  
  @media only screen and (min-width: 769px) {
    .LandingPageButton:not([disabled]):hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10)), #2CA0E2;
    }
  }
  
  .LandingPageButton:not([disabled]):active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), #2CA0E2;
  }

  .LandingPageButton:disabled {
    cursor: default;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), #2CA0E2;
  }

.LandingPageButton.attached-to-card {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.VideoCard {
  width: 100%;
  max-width: 720px;
  background: #252836;
  border-radius: 12px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  padding: 17px 0 23px 0;
  position: relative;
  margin-bottom: 130px;
}

.AdPageCardContainer {
  width: 100%;
  max-width: 720px;
  @media only screen and (max-width: 768px) {
    padding: 0 14px;
  }
}

.LandingPageButton::after {
  content: '';
  width: 16px;
  height: 16px;
  background: url('../../../public/open-cta-icon.svg') no-repeat center;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}

  
