/* VideoTitle.css */

.VideoTitle {
    display: flex;
    width: 100%;
    max-width: 720px;
    align-items: flex-start;
    gap: 16px;
    padding: 0 23px;
  }
  
  .AdvertiserAvatarContainer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .AdvertiserAvatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .VideoTitleText {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    overflow-wrap: break-word;
  }

  .SponsorNote {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.4);
  }

  