/* CommentPostModal.css */
.comment-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .comment-modal {
    position: relative;
    background-color: #252836;
    border-radius: 12px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 767px) {
    .comment-modal-overlay {
        align-items: flex-end;
    }

    .comment-modal {
        border-radius: 12px 12px 0 0;
        box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.25); 
        margin-bottom: env(safe-area-inset-bottom);
    }
  }
  
  .comment-modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .comment-modal-close,
  .comment-modal-submit {
    background: none;
    border: none;
    font-size: 1em;
    cursor: pointer;
  }
  
  .comment-modal-close {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .comment-modal-submit {
    position: absolute;
    top: 23px;
    right: 20px;
    align-self: flex-end;
    color: #252836;
    padding: 4px 12px;
    border-radius: 100px;
    background: #9E64F8;
  }

  .comment-modal-submit i {
    padding-left: 6px;
  }
  
  @media only screen and (min-width: 769px) {
    .comment-modal-submit:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #9E64F8;
    }
  }

  .comment-modal-submit:disabled {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #9E64F8;
  }
  
  .comment-modal-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 15px 20px 20px 20px;
  }

  .comment-modal-form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .comment-modal-label {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1em;
    font-weight: 600;
  }
  
  .comment-modal-input,
  .comment-modal-textarea {
    width: 100%;
    font-size: 1em;
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: white;
  }
  
  .comment-modal-textarea {
    height: 20vh;
  }
  