/* RewardCard.css */
.RewardCard {
  display: flex;
  max-width: 720px;
  width: 100%;
  padding: 17px 23px;
  flex-direction: column;
  background: #252836;
  border-radius: 12px;
}

/* 上方內容區塊 */
.RewardCardContentSection {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  padding-right: 17px;
}

/* 左側頭像 */
.AvatarContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.CreatorAvatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 右側文字內容 */
.RewardCardTextContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.CreatorName {
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  word-break: break-all;
}

.ThankYouText {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.35;
  text-align: left;
  font-size: 0.9rem;
}

/* 底部按鈕區塊 */
.TimeAndButton {
  display: flex;
  width: 100%;
  align-items: center;
}

.RemainTime {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.BonusButton {
  display: flex;
  padding: 0 17px;
  gap: 17px;
  width: 100%;
  justify-content: center;
}

/* 按鈕共用樣式 */
.BonusButton button {
  padding: 7px 15px;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  flex-grow: 1;
  white-space: nowrap;
}

/* Unlock按鈕 */
.UnlockButton {
  background: #9E64F8;
  color: #252836;
  border: none;
}

.UnlockButton::after {
  content: '';
  width: 17px;
  height: 17px;
  background: url('../../../public/unlock-button-icon.svg') no-repeat center;
  background-size: contain;
  margin-bottom: 1px;
}

/* Comment按鈕 */
.CommentLinkButton {
  background: transparent;
  border: 1px solid #9E64F8;
  color: #9E64F8;
}

/* PC端樣式 */
@media only screen and (min-width: 769px) {
  .UnlockButton:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #9E64F8;
  }

  .CommentLinkButton:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
  }
}

.UnlockButton:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), #9E64F8;
}

.CommentLinkButton:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
}