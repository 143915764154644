/* CommentCard.css */

.flip-card {
    width: 100%;
    position: relative;
    perspective: 1000px;
}

.flip-card-inner {
    width: 100%;
    position: relative;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card-inner.flipped {
    transform: rotateY(180deg);
}

.flip-card-inner.flipped .flip-card-back {
    pointer-events: auto;
}

.flip-card-front, .flip-card-back {
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 12px;
    background: #252836;
    width: 100%;
    max-height: 50vh;
    overflow: auto;
    text-align: start;
    -webkit-overflow-scrolling: touch;
}

.flip-card-front {
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 1px 4px 9px 0px rgba(0, 0, 0, 0.04);
}

.flip-card-back {
    border: 1px solid rgba(158, 100, 248, 0.70);
    box-shadow: 1px 4px 18px 0px rgba(158, 100, 248, 0.25);
    transform: rotateY(180deg);
    pointer-events: none;
}

.discussion {
    display: flex;
    padding: 15px 20px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2em;
    color: rgba(255, 255, 255, 0.7);
    white-space: pre-wrap;
}

.discussion-info {
    display: inline-flex;
    padding-right: 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.audience-name {
    font-weight: 600;
    color: #ffffff;
}

.creator-name {
    font-weight: 600;
    color: #ffffff;
}


.discussion p {
    line-height: 150%;
    margin: 0;
}

.flip-card-front::-webkit-scrollbar, .flip-card-back::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.flip-card-front::-webkit-scrollbar-thumb, .flip-card-back::-webkit-scrollbar-thumb {
    background: #9E64F8;
    border-radius: 10px;
}

.flip-card-front::-webkit-scrollbar-track, .flip-card-back::-webkit-scrollbar-track {
    margin: 10px;
}

.show-tip {
    font-size: 0.95em;
    display: inline-block;
    background: rgba(37, 40, 54, 0.9);
    color: #9E64F8;
    cursor: pointer;
    padding: 3px 12px;
    border-radius: 50px;
    border: 1px solid #9E64F8;
}

.show-tip-wrapper {
    position: sticky;
    text-align: center;
    bottom: 8px;
}