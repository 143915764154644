/* CommentPage.css */
.comment-page {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    box-sizing: border-box;
    background-color: #1F1D2B;
    padding-bottom: 20vh;
}

.commentCard-wrapper {
    width: 100%;
    max-width: 720px;
    margin-bottom: 50px;
    padding: 0 14px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.commentPostButton {
    position: fixed; 
    bottom: calc(19px + env(safe-area-inset-bottom));
    right: 20px; 
    z-index: 3;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #9E64F8;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 0px 5px 8px 0px rgba(158, 100, 248, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
}

.commentPostButton i {
    padding-left: 2px;
    font-size: 19px;
}

.commentPostButton:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #9E64F8;
}
