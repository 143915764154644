/* VideoPlayer.css */

  .video-container {
    position: relative; 
    width: 100%;
    max-width: 720px; 
  }

  .video-container video {
    background-color: black;
  }
  
  .mute-control-container {
    position: absolute;
    bottom: 10px; 
    right: 10px; 
    z-index: 2; /*確保靜音按鈕在最上層*/
  }
  
  .mute-control {
    background-color: rgba(0, 0, 0, 0.3); 
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .mute-control:hover {
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .mute-control i {
    opacity: 1; 
  }
  
  .play-pause-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 45px;
    color: white;
    opacity: 0; 
    transition: opacity 0.4s ease
  }
  
  .play-pause-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .play-pause-icon.visible {
    opacity: 1;
    transition: opacity 0.4s ease; /* Add the transition property */
  }

