/* CommentSubject.css */
.comment-subject-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 720px;
  padding: 0 23px;
}

.comment-subject {
  color: #ffffff;
  width: 100%;
  max-width: 100%;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.comment-subject h3 {
  line-height: 1.35;
  margin: 0px;
}

.comment-subject-note {
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 0.9em;
  margin-bottom: 10px;
}