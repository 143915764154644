/* VideoPlayer.css */

.progress-bar-container {
    position: absolute;
    bottom: 4px;   /*0;2;1*/
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.3); 
  }
  
  .progress-bar {
    height: 100%;
    background-color: #2CA0E2;
  }

  