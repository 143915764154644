/* BrandLogoHeader.css */
.brand-logo-header {
  width: 100%;
  padding: 24px 17px 6px 17px;
  max-width: 720px;
}

.brand-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 0.4;
}

.brand-logo-line-left {
  flex: 1;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.brand-logo-line-right {
  flex: 1;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.brand-logo-img {
  width: 35px;
  height: 35px;
}