/* CollectionsPage.css */
.collections-page {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  box-sizing: border-box;
  background-color: #1F1D2B;
  padding-bottom: 20vh;
}

/* 集合容器 */
.collections-wrapper {
  width: 100%;
  max-width: 720px;
  margin-bottom: 50px;
  padding: 0 14px;
}

.collection-link {
  text-decoration: none;
  display: block;
}

.collection-card {
  background: #252836;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 1px 4px 9px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  height: 100%;
}

.collection-content {
  padding: 15px 20px;
  min-height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #252836;
}

.collection-text {
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  text-overflow: ellipsis;
  line-height: 150%;
  color: rgba(255, 255, 255);
}

.collection-footer {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.collection-name {
  color: rgba(255, 255, 255, 0.7);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
}

.collection-action {
  font-size: 0.9em;
  padding: 3px 12px;
  border-radius: 50px;
  border: 1px solid #9E64F8;
  color: #9E64F8;
  white-space: nowrap;
}

/* 加載更多和無內容提示 */
.no-collections {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 20px;
}

.loading-spinner {
  text-align: center;
  margin-top: 20px;
}

.load-more-trigger {
  height: 1px;
  margin-top: 20px;
}
